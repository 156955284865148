import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import React from "react"
import useStations from "../utils/hooks/useStations"

const AboutWeatherStationsPage = () => {
  const { station } = useStations()
  return (
    <Layout station={station}>
      <Seo title="About Weather Stations" />

      <div className="container px-3 mx-auto container-article xl:pt-6 lg:px-4 xl:px-12">
        <div className="grid grid-cols-1 gap-6 mb-8 lg:mb-12 lg:grid-flow-col xl:gap-12 md:grid-cols-12">
          <div className="md:col-span-8">
            <h1>About Weather Stations</h1>

            <p className="intro">
              Weather is the single most important thing a farmer should know.
            </p>

            <h2>Buy a Weather Station</h2>
            <p>
              NEWA works with <Link to="https://www.onsetcomp.com/corporate/partners/newa">Onset Data Loggers</Link> and <Link to="https://kestrelmet.com">KestrelMet</Link>, which are
              configured for NEWA’s agricultural production tools. Visit our{" "}
              <Link to="/buy-a-weather-station">Buy a Weather Station</Link>{" "}
              page to contact vendors and understand the steps required for
              connecting with NEWA resources.
            </p>

            <h2>Partners</h2>
            <p>
              Fifteen US states are in the NEWA network. Your annual membership
              fee is covered in full if you live in one of these regions. Visit
              our <Link to="/partners">Partners</Link> page to learn more.
            </p>

            <h2>Become a Partner</h2>
            <p>
              Not in the NEWA network? Visit our{" "}
              <Link to="/become-partner">Become a Partner</Link> page for more
              information about state-level NEWA membership in your region.
            </p>

            <hr></hr>

            <h2>Weather station sensors</h2>
            <p>
              <Link to="https://www.onsetcomp.com/corporate/partners/newa">Onset Data Loggers</Link> and <Link to="https://kestrelmet.com">KestrelMet</Link> configure their machines to
              include the required sensors for NEWA crop and IPM tools.
            </p>
            <ul className="lg:grid lg:grid-cols-2 lg:col-gap-4">
              <li>Air temperature</li>
              <li>Relative humidity</li>
              <li>Precipitation</li>
              <li>Leaf wetness</li>
              <li>Solar radiation</li>
              <li>Windspeed and wind direction</li>
              <li>Soil temperature (optional)</li>
              <li>Soil moisture (optional)</li>
            </ul>

            <p>
              If you own a weather station, visit our{" "}
              <Link to="/placement-guide">Placement Guide</Link> page to learn
              more about collecting accurate and unobstructed sensor data on
              your farm.
            </p>

            <p>
              Maintaining your weather station is key. Visit our{" "}
              <Link to="/maintenance-guide">Maintenance Guide</Link> page to
              learn how to keep sensors and the weather station working
              properly.
            </p>

            <div className="pl-4 mb-10 border-l-2 lg:pl-6">
              <h3>Leaf wetness estimation</h3>
              <p>
                Leaf wetness is an important measurement for some NEWA models
                but airport locations and some other weather stations do not
                have these sensors. For these stations, NEWA uses average
                relative humidity  greater than 90% to log a leaf wetness hour.
              </p>
            </div>

            <hr></hr>

            <h2>NEWA’s data quality control</h2>

            <p>
              Quality control methods are applied to sensor data coming from the
              weather stations linked with NEWA using a suite of protocols.
              These methods ensure datasets used by the NEWA crop and IPM tools
              are precise and accurate.
            </p>

            <div className="pl-4 mb-10 border-l-2 lg:pl-6">
              <h3>Station outage reports</h3>
              <p>
                The NEWA Help Desk notifies weather station owners by email when
                a data outage occurs. Messages are sent after outage periods of
                24 hours, 7 days, and 21 days. The weather station is removed
                temporarily (status inactive) from the NEWA website after 21
                days but is reinstated after the problem is fixed.
              </p>
            </div>

            <div className="pl-4 mb-10 border-l-2 lg:pl-6">
              <h3>Weather station status reporting</h3>
              <p>
                NEWA State Coordinators receive a daily status summary of all
                NEWA weather stations in their state. This report lists stations
                reporting data; lists stations not reporting with a timestamp of
                the last reported data; and lists inactive stations not
                reporting for over 21 days. The State Coordinator can then
                follow up with station owners or make referrals to the NEWA Help
                Desk if needed.
              </p>
            </div>

            <div className="pl-4 mb-10 border-l-2 lg:pl-6">
              <h3>Missing data</h3>
              <p>
                Temperature and relative humidity data points are missed
                occasionally in a data record and NEWA attempts to estimate
                missing values following a three-step process.
              </p>

              <ol>
                <li>
                  The data record is examined for non-missing values before and
                  after the missing data point and an average of the two is
                  used.
                </li>
                <li>
                  A sister station value is substituted if before and after
                  values are unavailable (see below).
                </li>
                <li>
                  If the sister station value is unavailable and the missing
                  data are temperature or relative humidity, data from the
                  National Weather Service Digital Forecast Database are used.
                </li>
              </ol>

              <p>
                Precipitation and wind direction can only be substituted from a
                sister station. If sister station data are missing the missing
                value is left blank in rare instances.
              </p>

              <p>
                Soil measurements are location dependent and are not substituted
                from sister stations in any scenario.
              </p>
            </div>

            <div className="pl-4 mb-10 border-l-2 lg:pl-6">
              <h3>Sister stations</h3>
              <p>
                Weather stations occasionally experience short-term outages. But
                NEWA models require continuous data to provide accurate results
                and recommendations. For this reason, every weather station
                linked with NEWA is paired with a ‘sister station’ that is
                chosen by meteorologists at the Northeast Regional Climate
                Center, to provide required data during an outage.
              </p>
            </div>

            <hr></hr>

            <h2>Weather stations in the network</h2>

            <p>
              In addition to <Link to="https://www.onsetcomp.com/corporate/partners/newa">Onset Data Loggers</Link> and <Link to="https://kestrelmet.com">KestrelMet</Link>, NEWA
              collects data from various partner state mesonets (independent
              networks of stations) and airports. The mesonets perform their own
              data quality control. Some stations may lack leaf wetness sensors,
              thus NEWA estimates this as described above.
            </p>

            <div className="pl-4 mb-10 border-l-2 lg:pl-6">
              <h3>Airport station data handling</h3>
              <p>
                Relative humidity (RH) data recorded at airport weather stations
                are usually not representative of conditions in an agricultural
                setting. Therefore, RH values observed at airports are adjusted
                in an attempt to make them more applicable for agricultural
                applications. NWS RH forecast values are also adjusted in the
                same way for all locations. The formula used for these
                adjustments is: adjRH = RH / (0.0047*RH + 0.53). This can be
                expressed graphically as:
              </p>
            </div>

            <figure>
              <img
                src="https://live-cu-newa.pantheonsite.io/wp-content/uploads/airport_rh_adjustment_graph.png"
                alt="A line graph representing the Relative Humidity adjustment formula"
              />
            </figure>

            <footer className="pt-6 mt-12 text-base border-t border-gray-200">
              <p>Page updated April 2020 by D. Olmstead and J. Carroll</p>
            </footer>
          </div>
          <div className="pt-4 text-base text-gray-700 border-t-2 border-gray-200 md:col-span-4 md:border-l-2 md:border-t-0 md:pt-3 md:pl-4 xl:pl-8">
            <h2>Get a Weather Station</h2>
            <p>
              NEWA is compatible with special configurations of {' '}
              <Link to="https://www.onsetcomp.com/corporate/partners/newa">
                Onset 
              </Link>{' '}
              and <Link to="https://kestrelmet.com/kestrelmet-6000-ag-weather-station-newa">KestrelMet</Link> weather instruments. See what your options are.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Buy a Weather Stations"
                to="/buy-a-weather-station"
              >
                Buy a Weather Station
              </Link>
            </p>

            <hr></hr>

            <h2>Have a question?</h2>
            <p>
              Reach out to our support center and ask questions to get support for NEWA tools, resources, and weather
              stations.
            </p>
            <p>
              <a
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                href="mailto:support@newa.zendesk.com"
              >
                Contact the NEWA Help Desk
              </a>
            </p>

            <hr></hr>

            <h2>Become a Partner</h2>
            <p>
              NEWA is a partnership of land grant universities and grower
              associations. If you live in a partner state, you can{" "}
              <Link to="/buy-a-weather-station">buy a weather station</Link> for
              your farm and connect to NEWA.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Learn More About Becoming a Partner"
                to="/become-partner"
              >
                Learn More
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutWeatherStationsPage
